<template>
  <div id="fund" class="py-10 pt-20 md:py-20">
    <h3 class="text-4xl mb-5">Fund Request for $CATVAX Project</h3>
    <div>
      <h4 class="text-3xl mt-5 text-green-500">Overview</h4>
      <p>We are thrilled to announce that we will make significant global donations to the victims of COVID-19 and other vaccine side effects. Furthermore, we will donate to cat shelters, to enhance cats' health globally by improving access to veterinary care and raising awareness about cat health issues.</p>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-10">
        <div>
          <p class="mt-5">To achieve these goals, we need financial support to:</p>
          <ul class="list-disc ms-10">
            <li>Manage and grow our social media channels.</li>
            <li>Coordinate and execute global donation campaigns.</li>
            <li>Cover operational costs such as maintenance of our website.</li>
          </ul>
        </div>
        <div>
          <p class="mt-5">Solana Donation Address:</p>
          <div class="w-full max-w-[32rem]">
            <div class="relative">
              <label for="copyDonationAddress" class="sr-only">Label</label>
              <input id="copyDonationAddress" type="text" class="col-span-6 border text-xs sm:text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-gray-400 focus:ring-blue-500 focus:border-blue-500" value="ErXKLe1UbyL6Bx1wZLQpFkiTn8La89RhX28Z5Fwef1Tt" disabled readonly>
              <button data-copy-to-clipboard-target="copyDonationAddress" data-tooltip-target="copyDonationAddressTooltip" class="absolute end-2 top-1/2 -translate-y-1/2 text-gray-400 hover:bg-gray-800 rounded-lg p-2 inline-flex items-center justify-center">
            <span id="default-icon">
                <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                    <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                </svg>
            </span>
                <span id="success-icon" class="hidden inline-flex items-center">
                <svg class="w-3.5 h-3.5 text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5" />
                </svg>
            </span>
              </button>
              <div id="copyDonationAddressTooltip" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 rounded-lg shadow-sm opacity-0 tooltip bg-gray-700">
                <span id="default-tooltip-message">Copy to clipboard</span>
                <span id="success-tooltip-message" class="hidden">Copied!</span>
                <div class="tooltip-arrow" data-popper-arrow></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
</script>
