<template>
  <nav class="border-gray-200 absolute top-0 left-0 right-0 z-10 bg-black bg-opacity-70 lg:bg-opacity-40">
    <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
      <a href="https://catvax.org" class="flex items-center space-x-3 rtl:space-x-reverse">
        <img src="../assets/catvax-logo.jpeg" class="h-14 rounded-[100%]" alt="Catvax Logo" />
        <span class="self-center text-2xl font-semibold whitespace-nowrap text-white">$CATVAX</span>
      </a>
      <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg md:hidden focus:outline-none focus:ring-2 text-white hover:shadow-inner hover:shadow-green-800/80 focus:ring-green-500" aria-controls="navbar-default" aria-expanded="false">
        <span class="sr-only">Open main menu</span>
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
        </svg>
      </button>
      <div class="hidden w-full md:block md:w-auto" id="navbar-default">
        <ul class="font-medium items-center flex flex-col p-4 md:p-0 mt-4 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 border-gray-700">
          <li>
            <a v-smooth-scroll href="#about" class="block py-2 px-3 text-white rounded md:border-0 md:p-0 md:hover:text-green-500 hover:shadow-inner hover:shadow-green-800/80 hover:text-white md:hover:bg-transparent md:hover:shadow-none">About</a>
          </li>
          <li>
            <a v-smooth-scroll href="#roadmap" class="block py-2 px-3 text-white rounded md:border-0 md:p-0 md:hover:text-green-500 hover:shadow-inner hover:shadow-green-800/80 hover:text-white md:hover:bg-transparent md:hover:shadow-none">Roadmap</a>
          </li>
          <li>
            <a v-smooth-scroll href="#partners" class="block py-2 px-3 text-white rounded md:border-0 md:p-0 md:hover:text-green-500 hover:shadow-inner hover:shadow-green-800/80 hover:text-white md:hover:bg-transparent md:hover:shadow-none">Partners</a>
          </li>
          <li>
            <a v-smooth-scroll href="#fund" class="block py-2 px-3 text-white rounded md:border-0 md:p-0 md:hover:text-green-500 hover:shadow-inner hover:shadow-green-800/80 hover:text-white md:hover:bg-transparent md:hover:shadow-none">Fund</a>
          </li>
          <li>
            <button type="button" class="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-800 shadow-lg shadow-green-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2" data-drawer-target="howToBuy" data-drawer-show="howToBuy" data-drawer-placement="right" aria-controls="howToBuy">
              How to buy
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>