<template>
  <ol class="relative border-s border-gray-700">
    <li class="mb-10 border-s border-green-600 ms-[-0.5px]">
      <div class="ms-4">
        <div class="absolute w-3 h-3 rounded-full mt-1.5 -start-1.5 border border-green-400 bg-green-600"></div>
        <time class="mb-1 text-xl font-normal leading-none text-white">Q2 - 2024</time>
        <p class="mb-4 font-normal text-white text-2xl">Marketing & Community Growth</p>
        <ul class="text-base font-normal text-gray-400 list-disc ms-5">
          <li>Launch of New Website</li>
          <li>Grow our Comm. on Telegram to 1k members</li>
          <li>Introduce a CATVAX Raid Army</li>
          <li>Develop and Implement a marketing plan</li>
          <li>Gain 3k followers on X Account</li>
          <li>Partnership with cat clinics for Donations.</li>
        </ul>
      </div>
    </li>
    <li class="mb-10 ms-4">
      <div class="absolute w-3 h-3 rounded-full mt-1.5 -start-1.5 border border-gray-900 bg-gray-700"></div>
      <time class="mb-1 text-xl font-normal leading-none text-white">Q3 - 2024</time>
      <p class="mb-4 font-normal text-white text-2xl">Listing & Donating</p>
      <ul class="text-base font-normal text-gray-400 list-disc ms-5">
        <li>Website improvements</li>
        <li>Grow the daily active raiding army to 100 people</li>
        <li>CMC listing</li>
        <li>New CEX listing</li>
        <li>Donate to cat shelters</li>
        <li>Donate to victims of COVID-19 side effects</li>
      </ul>
    </li>
  </ol>
</template>
<script setup>
</script>