<template>
  <Splide :options="{ perPage: 1, perMove: 1, autoplay: true, interval:2000, pauseOnHover: true, type:'loop', fixedHeight: '400px', fixedWidth:'400px'}" aria-label="catvaxxers">
    <SplideSlide>
      <img src="../assets/slides/IMG-20240426-WA0030.jpg" alt="cat 1">
    </SplideSlide>
    <SplideSlide>
      <img src="../assets/slides/IMG-20240426-WA0031.jpg" alt="cat 2">
    </SplideSlide>
    <SplideSlide>
      <img src="../assets/slides/IMG-20240426-WA0032.jpg" alt="cat 2">
    </SplideSlide>
    <SplideSlide>
      <img src="../assets/slides/IMG-20240426-WA0033.jpg" alt="cat 2">
    </SplideSlide>
    <SplideSlide>
      <img src="../assets/slides/IMG-20240426-WA0034.jpg" alt="cat 2">
    </SplideSlide>
    <SplideSlide>
      <img src="../assets/slides/IMG-20240426-WA0035.jpg" alt="cat 2">
    </SplideSlide>
    <SplideSlide>
      <img src="../assets/slides/IMG-20240426-WA0036.jpg" alt="cat 2">
    </SplideSlide>
    <SplideSlide>
      <img src="../assets/slides/IMG-20240426-WA0037.jpg" alt="cat 2">
    </SplideSlide>
    <SplideSlide>
      <img src="../assets/slides/IMG-20240426-WA0039.jpg" alt="cat 2">
    </SplideSlide>
    <SplideSlide>
      <img src="../assets/slides/IMG-20240517-WA0007.jpg" alt="cat 2">
    </SplideSlide>
    <SplideSlide>
      <img src="../assets/slides/IMG-20240517-WA0008.jpg" alt="cat 2">
    </SplideSlide>
    <SplideSlide>
      <img src="../assets/slides/IMG-20240517-WA0009.jpg" alt="cat 2">
    </SplideSlide>
    <SplideSlide>
      <img src="../assets/slides/IMG-20240517-WA0010.jpg" alt="cat 2">
    </SplideSlide>
    <SplideSlide>
      <img src="../assets/slides/IMG-20240517-WA0011.jpg" alt="cat 2">
    </SplideSlide>
    <SplideSlide>
      <img src="../assets/slides/IMG-20240517-WA0012.jpg" alt="cat 2">
    </SplideSlide>
    <SplideSlide>
      <img src="../assets/slides/IMG-20240517-WA0013.jpg" alt="cat 2">
    </SplideSlide>
  </Splide>
</template>
<script setup>
</script>