<template>
  <div id="dna" class="relative hidden md:block overflow-hidden">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>
<style lang="less">
@rotate-time: 4s;
@rotate-delay: 0.15;
@lines-color: rgba(255, 255, 255, 0.1);
@dots-color: white;

#dna {
  height: 240px;
  left: 50%;
  width: 800px;
  margin-left: -400px;
  perspective: 1000px;
}

#dna div {
  position: relative;
  top: 50%;
  left: 60px;
  width: 2px;
  height: 160px;
  margin-top: -80px;
  background-color: @lines-color;
  float: left;
  transform-style: preserve-3d;
  margin-left: 20px;
  animation: rotation @rotate-time linear infinite;
}

#dna div::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -4px;
  right: -4px;
  height: 10px;
  background-color: @dots-color;
  border-radius: 5px;
  animation: rotation @rotate-time linear infinite reverse;
  z-index: 1;
}

#dna div::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -4px;
  right: -4px;
  height: 10px;
  background-color: @dots-color;
  border-radius: 5px;
  animation: rotation @rotate-time linear infinite reverse;
  z-index: 1;
}

@iterations: 30;
.loop (@i) when (@i > 0) {
  @delay: (@i * @rotate-delay - 60);
  #dna div:nth-child(@{i}) {
    animation-delay: ~"@{delay}s";
  }
  #dna div:nth-child(@{i})::before {
    animation-delay: ~"@{delay}s";
  }
  #dna div:nth-child(@{i})::after {
    animation-delay: ~"@{delay}s";
  }
  .loop(@i - 1);
}

.loop(@iterations);

@keyframes rotation {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(359deg);
  }
}
</style>