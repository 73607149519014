import {createApp} from 'vue'
import '@splidejs/vue-splide/css'
import '../css/style.css'
import App from '../App.vue'
import VueSplide from '@splidejs/vue-splide'
import VueSmoothScroll from 'v-smooth-scroll'

const app = createApp(App)
app.use(VueSplide)
app.use(VueSmoothScroll)
app.mount('#app')

